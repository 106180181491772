import React from 'react';
import { Helmet } from "react-helmet";


const Alt = () => {
  return (
    // Container for everything
    <div className="bg-black text-white flex flex-col items-center">
            <Helmet>
              <title>its a me altaran</title>
            </Helmet>
      {/* Main Content Wrapper */}
      <div className="w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-y-auto flex flex-col items-center">
        
        {/* Blue Box Header */}
        <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
          <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
          <h1 className="text-center text-3xl font-bold mt-4">All about me....</h1>
        </header>

        {/* Main Content */}
        <div className="flex flex-col items-center justify-center w-full">
          
          {/* Section: Things I've put time into */}
          <h6 className="text-lg font-semibold text-gray-200 mt-4 border-2 border-white rounded-full py-2 px-4 text-center">
            Things I've put time into...
          </h6>
          <ul className="w-full max-w-md mt-4 border-2 border-white rounded-lg p-4">
            <li className="bg-white shadow-md p-3 rounded-md mt-2 text-[#6a0dad] font-bold text-center flex items-center justify-center">
              <img src="/bob.png" alt="Bob" className="w-8 h-8 mr-2" />
              <a href="https://altcology.us" target="_blank" rel="noopener noreferrer" className="uppercase">altcology.us</a>
            </li>
            <li className="flex items-center justify-center bg-white shadow-md p-3 rounded-md mt-2 text-[#6a0dad] font-bold">
              <img src="/bob.png" alt="Bob" className="w-8 h-8 mr-2" />
              <a href="https://bobsnews.altaran.us" target="_blank" rel="noopener noreferrer" className="uppercase">boB's News</a>
            </li>
            <li className="flex items-center justify-center bg-white shadow-md p-3 rounded-md mt-2 text-[#6a0dad] font-bold">
              <img src="/bob.png" alt="Bob" className="w-8 h-8 mr-2" />
              <a href="https://shirtsbybob.altaran.us/" target="_blank" rel="noopener noreferrer" className="uppercase">Shirts By Bob</a>
            </li>
          </ul>

          {/* Section: Things I enjoy */}
          <h6 className="text-lg font-semibold text-gray-200 mt-6 border-2 border-white rounded-full py-2 px-4 text-center">
            ...and things I enjoy
          </h6>
          <ul className="w-full max-w-md mt-4 border-2 border-white rounded-lg p-4">
            <li className="bg-white shadow-md p-3 rounded-md mt-2 text-[#6a0dad] font-bold text-center">
              <a href="https://altaran.us/" className="uppercase">Thing 2</a>
            </li>
            <li className="bg-white shadow-md p-3 rounded-md mt-2 text-[#6a0dad] font-bold text-center">
              <a href="https://altaran.us/" className="uppercase">Thing 3</a>
            </li>
          </ul>
        </div>
      </div>

      {/* Fancy Divider */}
      <div className="my-32 mx-60">
        <hr className="border-t-6 border-white mx-auto w-4/5 transform -rotate-6 shadow-lg shadow-purple-800" />
      </div>

      {/* Banner Ad Box (at the bottom of the page) */}
      <div className="w-full p-4 mx-auto mt-20 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px]">
        <p className="text-white text-4xl font-bold text-center">
          Ads here soon
        </p>
      </div>

    </div>
  );
};

export default Alt;
