import React from "react";
import { Helmet } from "react-helmet";
import BobChatbot from "../bobBot/bobChatbot";

const AltAI = () => {
  const [conversationHistory] = React.useState([]);

  return (
    <div className="bg-black text-white flex flex-col items-center">
      <Helmet>
        <title>alt's ai bots</title>
      </Helmet>
      
      {/* Gradient Background Div */}
      <div className="w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-y-auto flex flex-col items-center">
        
        {/* Title Section */}
        <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
          <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
          <h1 className="text-center text-3xl font-bold mt-4">alt's ai bots</h1>
        </header>

        {/* Chatbot Section */}
        <div className="relative w-full flex flex-col items-center justify-start space-y-6 text-center p-4 pb-16">
          <div className="max-w-lg w-full bg-black p-6 rounded-2xl shadow-lg shadow-[#378F82]">
            <BobChatbot conversationHistory={conversationHistory} />
          </div>
        </div>
      </div>

      {/* Ad Box (Outside the Gradient Section) */}
      <div className="w-full p-4 mx-auto mt-80 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px] flex items-center justify-center">
        <p className="text-white text-4xl font-bold">Ads here soon</p>
      </div>
    </div>
  );
};

export default AltAI;
