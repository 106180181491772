import React, { useState } from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    
    const handleLinkClick = () => {
      setIsMenuOpen(false);
    };

    return (
      <li className="text-center">
        <Link
          to={to}
          onClick={handleLinkClick}
          className={`${
            isActive
              ? "text-white bg-purple-600 rounded-md p-2 text-xl"
              : "text-gray-300 hover:text-white hover:bg-purple-500 rounded-md p-2 text-xl"
          }`}
          {...props}
        >
          {children}
        </Link>
      </li>
    );
  };

  const homeMatch = useMatch("/");
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Helmet>
        <title>Mushroom Substrate Calculator</title>
        <meta name="description" content="Calculate the perfect ratio of substrate to grain spawn for your mushroom growing projects. Our calculator helps you determine the ideal amounts for any tub size to optimize your yields." />
      </Helmet>

      <nav
        className="flex justify-between items-center p-4"
        style={{ backgroundColor: "rgb(14, 13, 13)" }}
      >
        <Link
          to="/"
          className={`text-4xl font-black ${
            homeMatch
              ? "text-white bg-purple-600 rounded-md p-2"
              : "text-gray-300 hover:text-white hover:bg-purple-500 rounded-md p-2"
          }`}
        >
          altaran.us
        </Link>

        <div className="flex items-center ml-auto">
          <ul className="hidden md:flex space-x-6 mr-4">
            <CustomLink to="/AloeCam">aloeCam</CustomLink>
            <CustomLink to="/space">space</CustomLink>
            <CustomLink to="/alt">alt</CustomLink>
            <CustomLink to="/blocks">blocks</CustomLink>
          </ul>

          <button
            onClick={toggleMenu}
            className="md:block text-white p-2 focus:outline-none z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        <div
          className={`fixed top-0 right-0 w-full h-full bg-gray-900/90 backdrop-blur-sm transform ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out z-40 flex flex-col items-center justify-center`}
        >
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="w-2/5 max-w-2xl mb-8">
              <img
                src="/burgerMenu1.png"
                alt="Floating burger menu artwork"
                className="w-full h-auto mix-blend-screen filter drop-shadow-2xl"
                style={{
                  mixBlendMode: 'screen',
                  transform: 'translateZ(0)',
                }}
              />
            </div>
            
            <ul className="flex flex-col items-center space-y-4">
              <CustomLink to="/">home</CustomLink>
              <CustomLink to="/AloeCam">aloeCam</CustomLink>
              <CustomLink to="/space">space</CustomLink>
              <CustomLink to="/alt">alt</CustomLink>
              <CustomLink to="/blocks">blocks</CustomLink>
              <CustomLink to="/altAI">AI</CustomLink>
              <CustomLink to="/substrateCalc">Substrate Calculator</CustomLink>
              <CustomLink to="/new">New Link</CustomLink>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}