import React from 'react';
import RocketLaunchSchedule from "./launches";

const Space = () => (
  <div className="bg-black text-white flex flex-col items-center">
    
    {/* Main content wrapper */}
    <div className="relative w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white flex flex-col items-center overflow-y-auto">
      
      {/* Blue Box Header */}
      <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
        <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
        <h1 className="text-center text-3xl font-bold mt-4">Upcoming Launch Schedule</h1>
      </header>

      <div className="w-full max-w-4xl px-4 mt-8">
        <RocketLaunchSchedule />
      </div>
    </div>

    {/* Fancy Divider */}
    <div className="my-32 mx-60">
      <hr className="border-t-6 border-white mx-auto w-4/5 transform -rotate-6 shadow-lg shadow-purple-800" />
    </div>

    {/* Banner Ad Box (at the bottom of the page) */}
    <div className="w-full p-4 mx-auto mt-20 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px]">
      <p className="text-white text-4xl font-bold text-center">
        Ads here soon
      </p>
    </div>

  </div>
);

export default Space;
