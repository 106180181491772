import React from 'react';
import startMotor1 from '../aloecam/startmotor1';

const RandomButton = () => (
  <div className="flex justify-center items-center">
    <button 
className="bg-[#220033] text-white font-bold py-2 px-6 rounded-full border-2 border-white hover:bg-[#330044] transition-colors duration-300 hover:scale-105"

      onClick={startMotor1}
    >
      Random Button
    </button>
  </div>
);

export default RandomButton;