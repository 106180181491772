import React from 'react';
import { Helmet } from "react-helmet";
import AloeInfo from '../aloeInfo/aloeInfo';
import Clicks from './clicks';
import AloeCamFeed from './aloeCamFeed';
import UserCount from './userCount';

const pageTitle = "altaran.us";
const pageDescription = "Home of the aloeCam";

const Aloecam = () => {
  return (
    <div className="bg-black text-white flex flex-col items-center">
      <div className="w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-y-auto flex flex-col items-center">
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
        </Helmet>

        <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
          <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
          <h1 className="text-center text-3xl font-bold mt-4">
            {pageDescription}
          </h1>
        </header>

        <div className="relative w-full flex flex-col items-center justify-start space-y-6 text-center p-4 pb-16">
          <div className="w-full flex flex-col justify-center items-center min-h-[50vh] space-y-4">
            <div className="video-container w-full">
              <AloeCamFeed />
            </div>
          </div>
          <Clicks />
          <UserCount />
          <AloeInfo />
        </div>
      </div>

      <div className="w-full p-4 mx-auto mt-80 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px]">
        <p className="text-white text-4xl font-bold text-center">Ads here soon</p>
      </div>
    </div>
  );
};

export default Aloecam;
