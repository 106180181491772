const CDN_BASE_URL = 'https://altaran-us.b-cdn.net';

export const setsData = [
    {
        id: 1,
        name: "Black Scorcerer",
        images: [
            `${CDN_BASE_URL}/sets/black_Scorcerer/black_scorcerer1.jpg`,
            `${CDN_BASE_URL}/sets/black_Scorcerer/black_scorcerer2.jpg`,
            `${CDN_BASE_URL}/sets/black_Scorcerer/black_scorcerer3.jpg`
        ],
        youtubeUrl: "",
        description: "This was the first lego like set I have put together since I was a kid.  I really enjoyed it.  At the end I had a few extras and was missing one piece.  I can only assume with 1 missing piece it was my fault.  Either way, fun build.",
        manufacturer: "ZHEGAO",
        pieces: '1366+',
        rating: 5,
        dateStarted: "2025-01-27",
        dateFinished: "2025-01-31",
        manufacturerLink: "https://amzn.to/42YCV7Z",
    },
    {
        id: 2,
        name: "GBC Marble Run Planetary Elevator",
        images: [
            `${CDN_BASE_URL}/sets/marble_26015/marble1.jpg`,
            `${CDN_BASE_URL}/sets/marble_26015/marble2.jpg`,
            `${CDN_BASE_URL}/sets/marble_26015/marble3.jpg`
        ],
        youtubeUrl: "",
        description: "This will be my second set to date.  Can't get waited to started.  I finished this set, but put a pause on it to work on others.  Soon as I get some batteries and some free time, I will get back to it and post more pics.",
        manufacturer: "JMBricklayer",
        pieces: '1009+',
        rating: 0,
        dateStarted: "2025-02-05",
        dateFinished: "N/A",
        manufacturerLink: "https://amzn.to/4hnBswx",
    },
    {
        id: 3,
        name: "Mould King RC Bulldozer",
        images: [
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer1.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer2.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer3.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer4.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer5.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer6.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer7.jpg`,
            `${CDN_BASE_URL}/sets/bulldozer/bulldozer8.jpg`
        ],
        youtubeUrl: "",
        description: "This was an amazing set.  It was a little more difficult then the other ones I have put together.  Everything just worked.  Though my forward and reverse are backwards, and I do not know how to remedy that.",
        manufacturer: "Mould King",
        pieces: '1508+',
        rating: 5,
        dateStarted: "2025-02-08",
        dateFinished: "2025-02-10",
        manufacturerLink: "https://amzn.to/3Qofihv",
    },
    {
        id: 4,
        name: "JM Bricklayer Model A Ford",
        images: [
            `${CDN_BASE_URL}/sets/ford/ford1.jpg`,
            `${CDN_BASE_URL}/sets/ford/ford2.jpg`,
            `${CDN_BASE_URL}/sets/ford/ford3.jpg`,
            `${CDN_BASE_URL}/sets/ford/ford4.jpg`,
        ],
        youtubeUrl: "",
        description: "",
        manufacturer: "Mould King",
        pieces: '1297+',
        rating: 0,
        dateStarted: "2025-02-12",
        dateFinished: "",
        manufacturerLink: "https://share.temu.com/mNDOLyUlAqC",
    },
];