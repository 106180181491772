import React, { useEffect, useState } from "react";

const UserCount = () => {
  const [aloeCam, setCount] = useState([]);

  const fetchData = () => {
    fetch("https://api.altaran.us/userCount")
      .then(response => response.json())
      .then(data => setCount(data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
<div className="text-white text-center mt-[20px]">
  {aloeCam.length > 0 && (
    <div>
      <div className="text-[20px]">Total Unique* Users to Date:</div>
      {aloeCam.map(user => (
        <p 
          key={user.id}
          className={`
            text-white
            inline-block rounded-md border border-[#c584f3] 
            bg-gradient-to-b from-[#dfbdfa] to-[#270850] 
            px-[30px] py-[10px] font-bold font-arial text-[15px] 
            shadow-[inset_0px_1px_0px_0px_#efdcfb] 
            mb-[10px]
          `}
        >
          {user.aloeCam}
        </p>
      ))}
    </div>
  )}
</div>
  );
};

export default UserCount;