import React, { useState } from 'react';
import ChatBot from './bobChatbot';

const BobChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Chat toggle button - fixed at bottom right */}
      <button
        onClick={toggleChat}
        className="fixed bottom-6 right-6 w-16 h-16 rounded-full shadow-lg hover:scale-110 transition-transform duration-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 z-20"
      >
        <img
          src="/bob.png"
          alt="Chat with Bob"
          className="w-full h-full rounded-full"
        />
      </button>

      {/* Chat window - always floating above the button */}
      {isOpen && (
        <div
          className="fixed bottom-24 right-6 w-96 bg-white shadow-xl z-30 border border-gray-300 sm:rounded-lg animate-slide-up"
        >
          {/* Header with close button */}
          <div className="bg-gray-800 text-white p-2 rounded-t-lg flex justify-between items-center">
            <span className="text-sm">Chat with Bob</span>
            <button
              onClick={toggleChat}
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Chat content */}
          <ChatBot />
        </div>
      )}

      {/* Global animation styles */}
      <style jsx global>{`
        @keyframes slide-up {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        
        .animate-slide-up {
          animation: slide-up 0.3s ease-out forwards;
        }
      `}</style>
    </>
  );
};

export default BobChatWidget;
