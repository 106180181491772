import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const NewsWidget = () => {
  const [stories, setStories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const proxyUrl = "https://api.allorigins.win/raw?url=";
    const targetUrl = encodeURIComponent("https://bobsnews.altaran.us/");

    fetch(proxyUrl + targetUrl)
      .then((response) => response.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        const articles = Array.from(doc.querySelectorAll("article"))
          .slice(0, 10)
          .map((article) => {
            const titleElement = article.querySelector("h2");
            const contentText = article.textContent.replace(/\s+/g, " ").trim();
            const words = contentText.split(" ").slice(0, 30).join(" ") + "...";

            return {
              title: titleElement?.textContent || "",
              url: titleElement?.querySelector("a")?.href || "#",
              synopsis: words,
            };
          });

        setStories(articles);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const handlePrev = () => setCurrentIndex((prev) => Math.max(0, prev - 1));
  const handleNext = () =>
    setCurrentIndex((prev) => Math.min(stories.length - 1, prev + 1));

  const openPopup = (url) => {
    window.open(url, "bobsNewsPopup", "width=800,height=600,scrollbars=yes");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-6xl mx-auto">
      {/* Heading */}
      <h2 className="text-2xl font-bold text-center mb-4">
        boBs News - AI Stories and Podcasts
      </h2>

      <div className="h-[300px] overflow-hidden flex justify-center items-center">
        <div className="flex items-center space-x-4 h-full overflow-hidden">
          <button
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className="p-3 rounded-full hover:bg-dark-purple-700 disabled:opacity-50 transition duration-300 transform hover:scale-110"
          >
            <ChevronLeft className="w-6 h-6 text-dark-purple-900" />
          </button>

          <div className="flex-1 overflow-hidden">
            <div
              className="flex transition-transform duration-300 space-x-4"
              style={{ transform: `translateX(-${currentIndex * (100 / 3)}%)` }}
            >
              {stories.map((story, index) => (
                <div
                  key={index}
                  className="flex-none w-full sm:w-1/2 md:w-1/3 bg-purple-900 text-white rounded-lg shadow-sm p-4 cursor-pointer"
                  onClick={() => openPopup(story.url)}
                >
                  <div className="flex flex-col h-full">
                    <h3 className="text-lg font-semibold mb-3 pt-2 hover:text-blue-300">
                      {story.title}
                    </h3>
                    <p className="text-sm text-gray-300 flex-1 overflow-hidden">{story.synopsis}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={handleNext}
            disabled={currentIndex >= stories.length - 1}
            className="p-3 rounded-full hover:bg-dark-purple-700 disabled:opacity-50 transition duration-300 transform hover:scale-110"
          >
            <ChevronRight className="w-6 h-6 text-dark-purple-900" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsWidget;
