import React, { useEffect, useState } from "react";
import startMotor1 from "../aloecam/startmotor1";
import Sound1 from "../randomButton/sound1";

const SOUND_MAP = {
  'hohoho.mp3': 'Ho Ho Ho',
  'HappyNewYear.mp3': 'Happy New Year',
  'haha.mp3': 'Muahahaha',
  'doh.mp3': 'Doh!',
  'chunky.mp3': 'Chunky',
  'mario.mp3': 'Mario',
  'idiots.mp3': 'Idiots',
  'swimming.mp3': 'Just Keep Swimming',
  'guesswhosback.mp3': 'Guess Who\'s Back',
};

const Clicks = () => {
  const [clicks, setClicks] = useState([]);
  const [selectedSound, setSelectedSound] = useState('doh.mp3');
  const [disabled, setDisabled] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const fetchData = () => {
    fetch("http://api.altaran.us/clicks")
      .then(response => response.json())
      .then(data => setClicks(data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  function clickity2() {
    if (disabled) return;

    setDisabled(true);
    startMotor1();
    if (!isMuted) {
      Sound1(selectedSound);
    }
    fetchData();

    setTimeout(() => setDisabled(false), 5000);
  }

  return (
    <div className="flex flex-col items-center justify-center space-y-4 p-4 text-white">
      <div className="mb-4 flex flex-col items-center space-y-2">
        <button
          className={`
            inline-block cursor-pointer rounded-md border border-[#c584f3] 
            bg-gradient-to-b from-[#dfbdfa] to-[#270850] 
            px-[30px] py-[10px] text-white 
            font-bold font-arial text-[15px] 
            shadow-[inset_0px_1px_0px_0px_#efdcfb] 
            hover:opacity-90 mb-[10px]
            ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          onClick={clickity2}
          disabled={disabled}
        >
          Drop it Like It's Hot
        </button>

        <label className="flex items-center space-x-2 cursor-pointer">
          <input
            type="checkbox"
            checked={isMuted}
            onChange={() => setIsMuted(!isMuted)}
            className="hidden"
          />
          <div className={`
            px-4 py-2 rounded-md border border-[#c584f3]
            bg-gradient-to-b from-[#dfbdfa] to-[#270850]
            font-bold text-[15px] shadow-[inset_0px_1px_0px_0px_#efdcfb]
            ${isMuted ? 'opacity-50' : ''}
          `}>
            {isMuted ? 'Unmute Sound' : 'Mute Sound'}
          </div>
        </label>
      </div>

      {clicks.length > 0 && (
        <div className="text-center">
          {clicks.map(user => (
            <div key={user.id} className="mb-4">
              <div className="flex space-x-4 items-center justify-center">
                <p 
                  className={`
                    text-white 
                    inline-block rounded-md border border-[#c584f3] 
                    bg-gradient-to-b from-[#dfbdfa] to-[#270850] 
                    px-[30px] py-[10px] font-bold font-arial text-[15px] 
                    shadow-[inset_0px_1px_0px_0px_#efdcfb] 
                    mb-[10px] 
                    ${disabled ? 'opacity-50' : ''}
                  `}
                >
                  {user.id} Drops to Date: {user.clicks}
                </p>

                <div className="flex flex-col items-center space-y-2 relative">
                  <select
                    value={selectedSound}
                    onChange={(e) => setSelectedSound(e.target.value)}
                    className={`
                      appearance-none w-full max-w-[200px] 
                      bg-gradient-to-b from-[#dfbdfa] to-[#270850] 
                      border border-[#c584f3] rounded-md 
                      text-white font-arial font-bold text-[15px] 
                      px-[20px] py-[10px] 
                      shadow-[inset_0px_1px_0px_0px_#efdcfb]
                      relative
                    `}
                  >
                    {Object.entries(SOUND_MAP).map(([sound, name]) => (
                      <option
                        key={sound}
                        value={sound}
                        className="bg-[#270850] text-white"
                      >
                        {name}
                      </option>
                    ))}
                  </select>

                  <div className="pointer-events-none absolute right-[10px] top-[50%] transform translate-y-[-50%] text-white">
                    ▼
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Clicks;