import React from "react";
import { Route, Routes } from "react-router-dom";
import AloeCam from "./components/aloecam/aloecam";
import Home from "./components/home/home";
import Alt from "./components/alt/alt";
import ALTai from "./components/altAI/altAI";
import Space from "./components/space/space";
import SpaceXNews from "./components/space/spaceXNews";
import Navbar from "./components/navbar/navbar";
import BuildingBlockSets from "./components/blocks/blocks"; // Fixed import
import SubstrateCalc from "./components/substrateCalc/substrateCalc";
import Footer from "./components/footer/footer"; // Import the Footer component
import BobChatWidget from "./components/bobBot/popChat";


function App() {
  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <Navbar />
      {/* Container with flex-grow */}
      <div className="flex-grow flex flex-col">
        <div className="container mx-auto p-4 flex-1">
          <Routes>
            <Route path="/AloeCam" element={<AloeCam />} />
            <Route path="/" element={<Home />} />
            <Route path="/Alt" element={<Alt />} />
            <Route path="/altAI" element={<ALTai />} />
            <Route path="/space" element={<Space />} />
            <Route path="/spaceXNews" element={<SpaceXNews />} />
            <Route path="/blocks" element={<BuildingBlockSets />} />
            <Route path="/substrateCalc" element={<SubstrateCalc />} />
          </Routes>
        </div>
        <BobChatWidget />
      </div>
      <Footer /> {/* Footer added here */}
    </div>
  );
}

export default App;
