import React, { useState, useEffect } from 'react';

const AloeCamFeed = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    const videoElement = document.querySelector('.video-container');
    if (videoElement) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        videoElement.requestFullscreen();
        setIsFullscreen(true);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const startMotor1 = () => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        console.log(this.responseText);
      }
    };
    xhttp.open("GET", "https://aloecam.ddns.net:5000/motorOn", true);
    xhttp.send();
  };

  return (
    <div className="flex flex-col items-center justify-center text-center p-4 relative">
      <div className={`video-container w-full ${isFullscreen ? 'h-screen' : 'max-w-4xl'}`}>
        <img
          className={`w-full h-auto object-contain rounded-3xl border-4 border-white shadow-xl ${isFullscreen ? 'h-full' : ''} ${isFullscreen ? '' : 'transform hover:scale-105 transition-all duration-300'}`}
          src="https://aloecam.aloecam.duckdns.org/"
          alt="aloeCam"
        />
      </div>

      {!isFullscreen && (
        <button
          onClick={handleFullscreen}
          className="mt-4 px-6 py-3 bg-purple-800 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200 border border-purple-600 shadow-lg hover:shadow-xl"
        >
          Fullscreen
        </button>
      )}

      {isFullscreen && (
        <div className="fixed bottom-8 left-0 right-0 flex flex-col items-center space-y-4 z-10">
          <button
            onClick={startMotor1}
            className="px-6 py-3 bg-purple-800 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200 border border-purple-600 shadow-lg hover:shadow-xl text-lg font-semibold"
          >
            Ball Drop!
          </button>
          <button
            onClick={handleFullscreen}
            className="px-6 py-3 bg-purple-800 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200 border border-purple-600 shadow-lg hover:shadow-xl"
          >
            Exit Fullscreen
          </button>
        </div>
      )}
    </div>
  );
};

export default AloeCamFeed;