import React, { useEffect, useState } from "react"

const TopTenClicks = () => {
  const [clicks, setClicks] = useState([])
  const [lastClick, setDate] = useState([])

  const fetchData = () => {
    fetch("http://api.altaran.us/top25")
      .then(response => response.json())
      .then(data => {
        setClicks(data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData2 = () => {
    fetch("http://api.altaran.us/top25")
      .then(response => response.json())
      .then(data => {
        setDate(data)
      })
  }

  useEffect(() => {
    fetchData2()
  }, [])

  return (
    <div className="text-white flex items-center justify-center">
      {clicks.length > 0 && lastClick.length > 0 && (
        <div>
          {clicks.map((user, index) => (
            <p
              key={index}
              className={`${
                index % 2 === 0 ? "bg-gray-800" : "bg-gray-700"
              } p-2 font-bold`}
            >
              Clicks: {user.clicks} ----- {user.lastClick}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default TopTenClicks
