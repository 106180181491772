import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { setsData } from './setsData';

const BuildingBlockSets = () => {
    const [sets] = useState(setsData);
    const [selectedSet, setSelectedSet] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showingVideo, setShowingVideo] = useState(false);

    const openModal = (setIndex, imgIndex) => {
        setSelectedSet(setIndex);
        setCurrentImageIndex(imgIndex);
        setShowingVideo(false);
    };

    const closeModal = () => {
        setSelectedSet(null);
        setShowingVideo(false);
    };

    const toggleVideo = () => {
        setShowingVideo(!showingVideo);
    };

    const nextImage = () => {
        if (selectedSet !== null) {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sets[selectedSet].images.length);
        }
    };

    const prevImage = () => {
        if (selectedSet !== null) {
            setCurrentImageIndex((prevIndex) => (prevIndex - 1 + sets[selectedSet].images.length) % sets[selectedSet].images.length);
        }
    };

    return (
        <div className="bg-black text-white flex flex-col items-center">
            <Helmet>
                <title>altaran.us</title>
            </Helmet>
            <div className="w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-y-auto flex flex-col items-center">
                <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
                    <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
                    <h1 className="text-center text-3xl font-bold mt-4">Alts Building Block Obsession</h1>
                </header>
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 px-4">
                    {sets.map((set, setIndex) => (
                        <div
                            key={set.id}
                            className="overflow-hidden border-4 border-white bg-gray-50 rounded-lg shadow-xl transition-all duration-300 hover:shadow-2xl hover:-translate-y-1"
                        >
                            <div className="p-6 space-y-4">
                                <h2 className="text-2xl font-bold text-center mb-4 border-b-2 border-blue-500 pb-2 text-black">
                                    {set.name}
                                </h2>
                                <div className="relative group">
                                    <img
                                        src={set.images[0]}
                                        alt={set.name}
                                        className="w-full rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105 cursor-pointer"
                                        onClick={() => openModal(setIndex, 0)}
                                    />
                                </div>
                                <div className="flex justify-center gap-2 mt-2">
                                    {set.images.map((img, imgIndex) => (
                                        <img
                                            key={imgIndex}
                                            src={img}
                                            alt={`Thumbnail ${imgIndex + 1}`}
                                            className="w-16 h-16 rounded-md border-2 border-white cursor-pointer hover:opacity-75"
                                            onClick={() => openModal(setIndex, imgIndex)}
                                        />
                                    ))}
                                    {set.youtubeUrl && (
                                        <button
                                            className="w-16 h-16 rounded-md border-2 border-white flex items-center justify-center bg-red-600 hover:bg-red-700"
                                            onClick={() => {
                                                setSelectedSet(setIndex);
                                                setShowingVideo(true);
                                            }}
                                        >
                                            <span className="text-white text-2xl">▶</span>
                                        </button>
                                    )}
                                </div>
                                <div className="space-y-3 mt-4">
                                    <p className="text-gray-700">{set.description}</p>
                                    <div className="grid grid-cols-2 gap-4 text-sm">
                                        <div>
                                            <span className="font-semibold text-gray-600">Manufacturer:</span>
                                            <p className="text-blue-600">{set.manufacturer}</p>
                                        </div>
                                        <div>
                                            <span className="font-semibold text-gray-600">Pieces:</span>
                                            <p className="text-blue-600">{set.pieces.toLocaleString()}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="font-semibold text-gray-600">Rating:</span>
                                        <div className="text-yellow-400">
                                            {'★'.repeat(set.rating)}
                                            <span className="text-gray-300">{'☆'.repeat(5 - set.rating)}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="font-semibold text-gray-600">Started:</span>
                                        <p className="text-blue-600">{set.dateStarted}</p>
                                    </div>
                                    <div>
                                        <span className="font-semibold text-gray-600">Completed:</span>
                                        <p className="text-blue-600">{set.dateFinished}</p>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <a
                                        href={set.manufacturerLink}
                                        className="inline-block px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 text-sm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Where I found it
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full p-4 mx-auto mt-80 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px]">
                <p className="text-white text-4xl font-bold text-center">Ads here soon</p>
            </div>
            {selectedSet !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
                    <button onClick={closeModal} className="absolute top-4 right-4 text-white text-3xl">&times;</button>
                    {!showingVideo ? (
                        <>
                            <button onClick={prevImage} className="absolute left-4 text-white text-4xl">&#9664;</button>
                            <img
                                src={sets[selectedSet].images[currentImageIndex]}
                                alt="Full view"
                                className="max-w-3xl max-h-screen rounded-lg shadow-lg"
                            />
                            <button onClick={nextImage} className="absolute right-4 text-white text-4xl">&#9654;</button>
                            {sets[selectedSet].youtubeUrl && (
                                <button
                                    onClick={toggleVideo}
                                    className="absolute bottom-4 right-4 bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                                >
                                    Watch Video
                                </button>
                            )}
                        </>
                    ) : (
                        <div className="w-full max-w-4xl aspect-video">
                            <iframe
                                title={`${sets[selectedSet].name} Video`}
                                src={sets[selectedSet].youtubeUrl}
                                className="w-full h-full rounded-lg"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                            <button
                                onClick={toggleVideo}
                                className="absolute bottom-4 right-4 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                            >
                                Show Images
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default BuildingBlockSets;