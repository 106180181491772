import React from 'react';
import { CFooter } from '@coreui/react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="w-full bg-purple-900 relative mt-16"> 
      <CFooter className="px-4 py-6">
        <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex items-center gap-3">
            <img 
              src="favicon.png" 
              alt="alt logo" 
              className="h-8 w-8 hover:rotate-12 transition-transform"
            />
            <span className="text-gray-200 font-medium">
              &copy; {currentYear} theAltaran
            </span>
            <img 
              src="favicon.png" 
              alt="alt logo" 
              className="h-8 w-8 hover:rotate-12 transition-transform"
            />
          </div>

          <div className="flex gap-6">
            <a 
              href="https://www.youtube.com/@altaran" 
              className="text-gray-300 hover:text-white transition-colors"
              target="_blank" 
              rel="noopener noreferrer"
            >
              YouTube
            </a>
            <a 
              href="https://github.com/theAltaran" 
              className="text-gray-300 hover:text-white transition-colors"
              target="_blank" 
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          </div>
        </div>
      </CFooter>
    </footer>
  );
};

export default Footer;
