import React from 'react';
import { Helmet } from 'react-helmet';
import RandomButton from '../randomButton2/randomButton2';
import NewsWidget from '../newsWidget/newsWidget';

const Home = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Helmet>
        <title>altaran.us</title>
      </Helmet>
      <div className="relative w-full min-h-[2000px] bg-black">
        
        <div className="relative w-full h-screen min-h-[1500px] bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-hidden">
          <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
            <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
            <h1 className="text-center text-3xl font-bold mt-4">altaran.us</h1>
          </header>
          
          <img
            src="/swan1.png"
            alt="Swan"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0 w-auto h-auto max-w-full max-h-full object-contain"
          />
  
          <h3 className="absolute top-32 left-1/2 -translate-x-1/2 md:top-40 text-xl md:text-4xl font-bold animate-pulse tracking-widest drop-shadow-lg uppercase z-10 mb-20">
            Sometimes I just do things, because the voices tell me to.
          </h3>

          <h2 className="absolute bottom-20 right-10 md:bottom-32 md:right-40 text-xl md:text-3xl font-extrabold text-yellow-400 animate-bounce z-10">
            Do you like my penguins?
          </h2>
        
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:top-1/3 md:left-1/4 lg:top-2/3 lg:left-3/4 transition-all duration-500 z-10">
            <RandomButton />
          </div>
        </div>
        
        <div className="my-50 mx-60">
          <hr className="border-t-6 border-white mx-auto w-4/5 transform -rotate-6 shadow-lg shadow-purple-800" />
        </div>

        <div className="w-full p-4 mx-auto mt-10 border-8 border-white rounded-lg z-10 shadow-lg shadow-purple-800 h-[500px] relative">
          <NewsWidget />
        </div>

        <div className="my-50 mx-60">
          <hr className="border-t-6 border-white mx-auto w-4/5 transform rotate-6 shadow-lg shadow-purple-800" />
        </div>

        <div className="w-full p-4 mx-auto mt-10 border-8 border-white rounded-lg z-10 shadow-lg shadow-purple-800 h-[500px] relative">
          <h1 className="text-white text-4xl font-bold text-center mb-8">boBs Youtube - boB With a Voice</h1>
        </div>

        <div className="my-50 mx-60">
          <hr className="border-t-6 border-white mx-auto w-4/5 transform -rotate-6 shadow-lg shadow-purple-800" />
        </div>
        
        <div className="w-full p-4 mx-auto mt-20 border-8 border-white rounded-lg z-10 shadow-lg shadow-purple-800 h-[200px]">
          <p className="text-white text-4xl font-bold text-center">Ads here soon</p>
        </div>
      </div>
    </div>
  );
};

export default Home;