import React from 'react';

const SubstrateCalc = () => (
  <div className="bg-black text-white flex flex-col items-center">
    <div className="w-full min-h-screen bg-gradient-to-br from-gray-900 to-[#220033] text-white overflow-hidden flex flex-col items-center">
      <header className="w-full bg-gradient-to-b from-purple-700 to-purple-900 text-white rounded-lg p-6 shadow-2xl mb-8 border-2 border-purple-800">
        <div className="w-32 h-1 bg-white mx-auto mt-4 rounded-full opacity-75" />
        <h1 className="text-center text-3xl font-bold mt-4">Substrate Calculator</h1>
      </header>

      <div className="relative w-full flex flex-col items-center justify-start flex-grow p-4">
        <iframe
          src="https://altaran.us/spawnCalc.html"
          width="100%"
          height="1050px"
          title="Spawn Calculator"
          className="w-full"
          style={{ border: "none" }}
        ></iframe>
      </div>
    </div>

    <div className="w-full p-4 mx-auto mt-60 border-8 border-white rounded-lg shadow-lg shadow-purple-800 h-[200px]">
      <p className="text-white text-4xl font-bold text-center">Ads here soon</p>
    </div>
  </div>
);

export default SubstrateCalc;
