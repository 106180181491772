import React from 'react';
import TopTenList from './topTenList';

const AloeInfo = () => (
  <div id="infoHide" className="text-white p-4">
    <hr className="my-4" />
    <p className="text-3xl font-extrabold text-white underline mb-4 bg-gradient-to-r from-purple-900 to-purple-700 p-2 rounded-lg shadow-lg transform 
    hover:scale-105 transition-transform duration-300">aloeInfo
    </p>

    <br />

    <div className="mb-2 p-4 bg-gray-800 bg-opacity-60 rounded-md">
      <p className="mb-2">
        Marble Motor is on from 10AM EST to 10PM EST / 3PM to 3:00AM UTC**
      </p>
      <p className="mb-2">
        The counter will not update on your first click. But it does update the
        database in the background, so on your next click it will update by one.
      </p>
      <p className="mb-2">
        *tracking via IP for Now. Will have more options in the future.
      </p>
      <p className="mb-2">
        ** Can't have marbles dropping while Fiona and I sleep now can I?
      </p>
    </div>

    <br />
    <hr className="my-4" />
    <div className="text-center">
    <p className="text-3xl font-extrabold text-white underline mb-4 bg-gradient-to-r from-purple-900 to-purple-700 p-2 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
        Top 25 Clickers* and Their Last Click
      </p>
      <TopTenList />
      <hr className="my-4" />
    </div>
  </div>
);

export default AloeInfo;
